import { ExtPurchaseRequestPostBody } from "../helpers";
import { MeetingTaskInCharge } from "./meeting";
import {
  BaseModel,
  BomDocument,
  BomLeveled,
  BomLeveledApproval,
  BomLeveledIndividualEntity,
  BomLeveledRecursive,
  BomMulti,
  Deadline,
  DeadlineDetail,
  DepartmentTemplate,
  ExtItemReq,
  ExtProjectProductPanelTemplate,
  ExtProjectWorkOrder,
  ExtPurchaseOrder,
  ExtPurchaseRequestPostBodyItemJson,
  ExtPurchaseRequestPostBodyJson,
  FepDetail,
  FepDocument,
  FepLeveled,
  FepLeveledFepLeveledProgram,
  FepLeveledFepLeveledProgramView,
  FepLeveledPart,
  FepLeveledPartView,
  FepLeveledProgram,
  FepLeveledView,
  FepMulti,
  IndividualEntity,
  Integration,
  IntegrationModule,
  Item,
  Job,
  JobBPO,
  JobBomLeveled,
  JobBomLeveledView,
  JobDJO,
  JobFep,
  JobFepLeveled,
  JobFepLeveledView,
  JobIntegrationList,
  JobIntegrationListView,
  JobMapped,
  JobPO,
  JobPanelCodeSerialNumberDone,
  JobPanelType,
  JobRemark,
  JobSummary,
  JobSummaryExtPurchaseOrder,
  JobSummaryExtPurchaseOrderView,
  JobSummaryView,
  JobType,
  JobView,
  Machine,
  MachineLog,
  MachinePic,
  MachineProgramExclusion,
  Manufacturer,
  MaterialRequest,
  MaterialRequestAuthorization,
  MaterialRequestItem,
  MaterialRequestItemView,
  MaterialRequestMaterialRequestItem,
  MaterialRequestMaterialRequestItemView,
  MaterialRequestView,
  PanelCode,
  PanelCodeDepartmentTemplate,
  PanelCodeDepartmentTemplateItem,
  PanelCodeMachineProgram,
  PanelCodePlan,
  PanelCodeRemark,
  PanelCodeSerialNumber,
  PanelCodeSerialNumberManual,
  PanelCodeSerialNumberRect,
  PanelCodeWiringProcessType,
  PanelDeadline,
  PanelTemplate,
  PanelTemplateMechanicalProcessType,
  PanelTemplateMechanicalProcessTypeBlacklist,
  PanelTemplateProcessTypeGeneral,
  PanelTemplateProcessTypeGeneralBlacklist,
  PanelTemplateProcessTypeGeneralItem,
  PanelTemplateProcessTypeGeneralItemBlacklist,
  PanelTemplateProcessTypeGeneralItemSubProcess,
  PanelTemplateProcessTypeGeneralItemSubProcessBlacklist,
  PanelTemplateProcessTypeGeneralItemSubProcessWorker,
  PanelType,
  PartToPart,
  PartToPartDetail,
  PartToPartNeeded,
  ProblemType,
  ProcessType,
  ProcessTypeGeneral,
  ProcessTypeGeneralItem,
  ProcessTypeGeneralItemSubProcess,
  ProcessTypeGeneralItemSubProcessView,
  ProcessTypeGeneralItemView,
  ProcessTypeGeneralView,
  Project,
  ProjectView,
  Ral,
  SimplifiedProcessType,
  Uom,
  User,
  Warehouse,
  WiringFepDocument,
  WiringModule,
  WiringWorkOrder,
  WorkOrderActualReject,
  WorkOrderActualRev,
  WorkOrderRev,
  WorkOrderRevWorker,
} from "./model";

export const makeDateString = (date: Date) => {
  const y = `${date.getFullYear()}`;
  const m =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${y}-${m}-${d}`;
};

export const initialWorkOrderActualRev: WorkOrderActualRev = {
  amount: 0,
  dateTime: new Date().toISOString(),
  id: 0,
  remark: "",
  uuid: "",
  workOrder: null,
  workOrderActualRejects: [],
  workOrderRev: null,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialWorkOrderRev: WorkOrderRev = {
  amount: 0,
  date: new Date().toISOString().slice(0, 10),
  endTime: 0,
  fepMulti: null,
  id: 0,
  job: null,
  jobId: 0,
  jobName: "",
  machine: null,
  name: "",
  partToPart: null,
  startTime: 0,
  uuid: "",
  workOrderActualRevs: [],
  workOrderActuals: [],
  workers: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  workOrderRevWorkers: [],
  actualH: 0,
  actualM: 0,
  actualS: 0,
  remark: "",
};

export const initialWorkOrderActualReject: WorkOrderActualReject = {
  id: 0,
  item: null,
  qty: 0,
  uuid: "",
  workOrderActual: null,
  workOrderActualRev: null,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialPanelDeadline: PanelDeadline = {
  id: 0,
  uuid: "",
  date: "",
  qty: 0,
  jobId: 0,
  job: null,
  panelDeadlineActuals: [],
  createdAt: null,
  updatedAt: null,
};

export const initialDeadline: Deadline = {
  deadlineDetails: [],
  id: 0,
  job: null,
  name: "",
  uuid: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialWarehouse: Warehouse = {
  id: 0,
  itemWarehouses: [],
  materialRequestItems: [],
  name: "",
  uuid: "",
  warehouseTransactions: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialPartToPartNeeded: PartToPartNeeded = {
  id: 0,
  uuid: "",
  name: "",
  qty: 0,
  item: null,
  fepDocument: null,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialJobPurchaseOrder: JobPO = {
  extPurchaseOrderId: null,
  job: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
};
export const initialJobBPO: JobBPO = {
  extBPOId: null,
  job: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
};

export const initialJobBomLeveled: JobBomLeveled = {
  bomLeveled: null,
  extPanelCodeId: null,
  job: null,
  qty: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  panelCode: null,
};
export const initialBomLeveled: BomLeveled = {
  bomLeveled: null,
  bomLeveledGroup: null,
  bomLeveledIndividualEntities: null,
  bomLeveledSubGroup: null,
  bomLeveledsChildren: null,
  children: null,
  elecExists: null,
  extItemId: null,
  extItemToCreateId: null,
  extRequestItemId: null,
  forProcess: null,
  identifier: null,
  itemsCount: null,
  mechExists: null,
  name: null,
  qty: null,
  qtyView: null,
  remark: null,
  type: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  materialType: null,
  approvals: null,
  snapshotPriceIdr: null,
  snapshotPriceLast: null,
  inactive: null,
  deactivationDate: null,
  revision: 0,
};

export const initialJobDJO: JobDJO = {
  job: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  extDjoId: null,
};

export const initialFepDocument: FepDocument = {
  fepMultis: [],
  id: 0,
  name: "",
  partToPartNeededs: [],
  partToParts: [],
  previousFepDocument: null,
  unitQty: 0,
  uuid: "",
  version: 0,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialJob: Job = {
  id: 0,
  jobSummary: null,
  uuid: "",
  name: "",
  qty: 0,
  priority: "",
  qtyDone: 0,
  productionDeadline: new Date().toISOString().slice(0, 10),
  deliveryDeadline: new Date().toISOString().slice(0, 10),
  deadline: new Date().toISOString().slice(0, 10),
  deadlines: [],
  closed: false,
  closingDate: null,
  jobDate: new Date().toISOString().slice(0, 10),
  referenceType: "",
  item: null,
  poStatus: null,
  poNumber: "",
  fepDocument: null,
  fepName: "",
  fepVersion: 0,
  bomDocument: null,
  bomName: "",
  bomVersion: 0,
  ral: null,
  assignedTo: null,
  sales: null,
  bom: null,
  fep: null,
  individualEntity: null,
  materialRequestItems: [],
  purchaseRequests: [],
  jobRemarks: [],
  jobTypes: [],
  uom: null,
  panelTypes: [],
  panelCodes: [],
  jobPanelTypes: [],
  engineeringMechanicalDeadline: null,
  engineeringElectricalDeadline: null,
  productionProcessDeadline: null,
  workOrders: [],
  wiringWorkOrders: [],
  workOrderRevs: [],
  panelDeadlines: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  jobBomLeveleds: [],
  jobFepLeveleds: [],
  jobMaterialRequests: [],
  jobPurchaseRequests: [],
  extPurchaseOrderId: null,
  extAssignedToId: null,
  materialRequests: [],
  deadlinesData: null,
  jobBomLeveledsData: null,
  jobFepLeveledsData: null,
  jobMaterialRequestsData: null,
  jobPanelTypesData: null,
  jobPurchaseRequestsData: null,
  jobRemarksData: null,
  materialRequestItemsData: null,
  materialRequestsData: null,
  panelCodesData: null,
  panelDeadlinesData: null,
  panelTypesData: null,
  purchaseRequestsData: null,
  wiringWorkOrdersData: null,
  workOrderRevsData: null,
  workOrdersData: null,
  extCreatedById: null,
  hidden: false,
  ordering: null,
  unchangeable: null,
  integrations: null,
  jobIntegrationList: [],
  jobDeptCategory: null,
  jobEvents: null,
  jobVersions: null,
  version: null,
  jobPanelCodeSerialNumberDones: null,
  extProjectId: null,
  extCrmDjoId: null,
  extJdpPresetId: null,
  shortName: null,
  batchCount: null,
  jobDjos: null,
  jobPurchaseOrders: null,
  extBPOId: null,
  jobBPOs: null,
};

export const initialFep: FepDocument = {
  fepMultis: [],
  id: 0,
  name: "",
  partToPartNeededs: [],
  partToParts: [],
  previousFepDocument: null,
  unitQty: 0,
  uuid: "",
  version: 0,
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialIndividualEntity: IndividualEntity = {
  id: 0,
  job: [],
  name: "",
  type: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialManufacturer: Manufacturer = {
  id: 0,
  item: [],
  name: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialRal: Ral = {
  fepMultis: [],
  id: 0,
  jobs: [],
  name: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialItem: Item = {
  bomDetails: [],
  companyPartNumber: "",
  fepDetails: [],
  fepMultis: [],
  fepPartToPartsNeeded: [],
  fepSubProcessInputItems: [],
  fepSubProcessOutputItems: [],
  id: 0,
  itemWarehouses: [],
  jobs: [],
  manufacturer: null,
  materialRequestItems: [],
  materialType: "",
  partDescription: "",
  partName: "",
  partNumber: "",
  partToPartDetails: [],
  partToPartNeededs: [],
  partToParts: [],
  purchaseRequestItems: [],
  qty: 0,
  um: "",
  uom: null,
  warehouseTransactions: [],
  workOrderActualRejects: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialUser: User = {
  id: 0,
  jobsByAssignedTo: [],
  jobsBySales: [],
  manpowers: [],
  name: "",
  password: "",
  roles: [],
  username: "",
  workOrders: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  wiringWorkOrders: [],
  jobEntryAllowed: null,
};

export const initialMachine: Machine = {
  calendars: [],
  description: "",
  id: 0,
  machineLogs: [],
  maintenances: [],
  name: "",
  processType: null,
  purchasedDate: "",
  status: "",
  workOrders: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  simpleProcessType: null,
  pics: null,
};

export const initialMachineLog: MachineLog = {
  activationType: "",
  dateTime: "",
  id: 0,
  machine: null,
  remark: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialJobType: JobType = {
  id: 0,
  jobs: [],
  name: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialDeadlineDetail: DeadlineDetail = {
  actual: null,
  deadline: null,
  id: 0,
  name: "",
  plan: null,
  uuid: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialUom: Uom = {
  id: 0,
  name: "",
  jobs: [],
  createdAt: null,
  updatedAt: null,
  createdBy: null,
};

export const initialWiringFepDocument: WiringFepDocument = {
  connections: 0,
  createdAt: null,
  createdBy: null,
  crimpingTime: 0,
  groupingTime: 0,
  id: 0,
  name: "",
  timePerConnection: 0,
  tubingTime: 0,
  updatedAt: null,
  uuid: "",
  wiringCablePreparations: [],
  wiringInterconnections: [],
  wiringModules: [],
  wiringPanelPreparations: [],
};

export const initialWiringModule: WiringModule = {
  connections: 0,
  id: 0,
  name: "",
  uuid: "",
  wiringFepDocument: null,
  type: "WK",
};

export const initialJobRemark: JobRemark = {
  createdAt: null,
  createdBy: null,
  id: 0,
  job: null,
  remark: "",
  updatedAt: null,
  uuid: "",
  problemType: null,
  done: false,
};

export const initialProblemType: ProblemType = {
  createdAt: null,
  createdBy: null,
  id: 0,
  name: "",
  updatedAt: null,
  uuid: "",
};

export const initialPanelType: PanelType = {
  code: "",
  createdAt: null,
  createdBy: null,
  id: 0,
  name: "",
  updatedAt: null,
  uuid: "",
  job: null,
};

export const initialJobPanelType: JobPanelType = {
  createdAt: null,
  createdBy: null,
  id: 0,
  job: null,
  panelType: null,
  qty: 0,
  updatedAt: null,
  uuid: "",
};

export const initialPanelCode: PanelCode = {
  createdAt: null,
  createdBy: null,
  closed: false,
  deadline: new Date().toISOString().slice(0, 10),
  id: 0,
  job: null,
  name: "",
  updatedAt: null,
  uuid: "",
  connections: 0,
  type: "",
  panelCodeWiringProcessTypes: [],
  wiringWorkOrders: [],
  serialNumber: "",
  panelCodePlans: [],
  departmentTemplates: null,
  departmentTemplatesData: null,
  panelCodeDepartmentTemplateItemStatuses: null,
  panelCodeDepartmentTemplateItemStatusesData: null,
  extCreatedById: null,
  hidden: false,
  ordering: null,
  unchangeable: null,
  panelCodeDepartmentTemplates: [],
  panelCodeDepartmentTemplateItems: [],
  panelCodeMachinePrograms: [],
  qty: null,
  jobIntegrationLists: null,
  jobId: null,
  machinePrograms: null,
  panelCodeSerialNumbers: [],
  panelCodeType: null,
  version: null,
  extItemId: null,
  panelCodeFirstWorkOrder: null,
  panelCodeSerialNumberManuals: null,
  panelCodeRemarks: null,
  extDefaultQaId: null,
  extDefaultQcId: null,
  extCrmPurchaseOrderItemId: null,
  panelCodeSerialNumberRects: null,
  extJdpPresetId: null,
  batch: null,
  extMaterialInstallationItemId: null,
  panelCodeMachineProgramExclusions: null,
};

export const initialPanelCodePlan: PanelCodePlan = {
  createdAt: null,
  createdBy: null,
  date: "2020-01-01",
  id: 0,
  finished: false,
  finishedDate: null,
  name: "",
  panelCode: null,
  updatedAt: null,
  uuid: "",
};

export const initialWiringWorkOrder: WiringWorkOrder = {
  createdAt: null,
  createdBy: null,
  date: null,
  id: 0,
  job: null,
  panelCode: null,
  jobName: "",
  panelCodeName: "",
  panelCodeSerialNumber: "",
  panelCodeType: "",
  updatedAt: null,
  isCustom: false,
  uuid: "",
  wiringProcessType: "",
  wiringProcessTypeFix: null,
  worker: null,
  deadlineDetail: null,
  isCancelled: false,
  isDone: false,
  wiringDescription: "",
  wiringRemark: "",
  doneStatus: "ON_PROGRESS",
  extMeetingTaskId: null,
  line: "",
  zone: "",
};

export const initialPanelCodeWiringProcessType: PanelCodeWiringProcessType = {
  id: 0,
  includes: false,
  panelCode: null,
  wiringProcessType: null,
};

export const initialBomDocument: BomDocument = {
  bomMultis: [],
  boms: [],
  createdAt: null,
  createdBy: null,
  id: 0,
  name: "",
  updatedAt: null,
  uuid: "",
  version: 0,
  number: "",
  elecExists: null,
  mechExists: null,
};

export const initialBomMulti: BomMulti = {
  bomDocument: null,
  bomMulti: null,
  children: [],
  createdAt: null,
  createdBy: null,
  extItemId: 0,
  id: 0,
  qty: 0.0,
  updatedAt: null,
  uuid: "",
};

export const initialItemReq: ExtItemReq = {
  id: 0,
  partName: "",
  partNum: "",
  partDesc: "",
  mfr: "",
  defaultUm: "",
  status: 0,
  createdAt: "",
  updatedAt: "",
  type: null,
};

export const initialMaterialRequestItem: MaterialRequestItem = {
  createdAt: null,
  createdBy: null,
  id: 0,
  item: null,
  extItemId: null,
  job: null,
  jobId: null,
  jobName: null,
  materialRequest: null,
  materialRequestItemAcquisitions: [],
  name: "",
  qty: 0,
  updatedAt: null,
  warehouse: null,
  extReserveListId: null,
  extInventoryId: null,
  itemData: null,
  warehouseData: null,
  bomItemType: null,
  extPanelCodeId: null,
  defaultUm: null,
  mfr: null,
  originalCurrency: null,
  originalPrice: null,
  partDesc: null,
  partName: null,
  partNum: null,
  priceIdr: null,
  snapshotPriceIdr: null,
  categoryPartNum: null,
  snapshotPriceDate: null
};

export const initialFepMulti: FepMulti = {
  children: [],
  createdAt: null,
  createdBy: null,
  fepDetails: [],
  fepDocument: null,
  fepMulti: null,
  id: 0,
  item: null,
  length: 0,
  material: "",
  name: "",
  qty: 0,
  ral: null,
  topLevel: false,
  type: "FEP",
  updatedAt: null,
  uuid: "",
  width: 0,
  workOrderRevs: [],
};

export const intialPartToPart: PartToPart = {
  children: [],
  createdAt: null,
  createdBy: null,
  fepDocument: null,
  height: 0,
  id: 0,
  item: null,
  length: 0,
  name: "",
  partToPart: null,
  partToPartDetails: [],
  processType: null,
  programRun: 0,
  timeNest: 0,
  timePerUnit: 0,
  timeRun: 0,
  unitQty: 0,
  updatedAt: null,
  uuid: "",
  width: 0,
  workOrderRevs: [],
};

export const initialPartToPartDetail: PartToPartDetail = {
  createdAt: null,
  createdBy: null,
  id: 0,
  item: null,
  length: 0,
  material: "",
  name: "",
  partToPart: null,
  materialType: null,
  qtyNeeded: 0,
  qtyProduced: 0,
  updatedAt: null,
  uuid: "",
  width: 0,
};

export const initialFepDetail: FepDetail = {
  bendStrokes: 0,
  createdAt: null,
  createdBy: null,
  fepMulti: null,
  id: 0,
  item: null,
  processType: null,
  time: 0,
  updatedAt: null,
  uuid: "",
  weldValue: 0,
};

export const initialProcessType: ProcessType = {
  createdAt: null,
  createdBy: null,
  fepDetails: [],
  fepPartToPartNeeded: [],
  fepProcess: [],
  id: 0,
  ordering: null,
  machines: [],
  name: "",
  partToParts: [],
  updatedAt: null,
  hidden: false,
};

export const initialBaseModel: BaseModel = {
  id: 0,
  createdAt: null,
  updatedAt: null,
  extCreatedById: null,
  hidden: false,
  ordering: 0,
  uuid: "",
  unchangeable: null,
  version: null,
};

export const initialProcessTypeGeneral: ProcessTypeGeneral = {
  ...initialBaseModel,
  name: "",
};

export const initialProcessTypeGeneralView: ProcessTypeGeneralView = {
  processTypeGeneral: null,
  processTypeGeneralItems: [],
};

export const initialProcessTypeGeneralItem: ProcessTypeGeneralItem = {
  ...initialBaseModel,
  processTypeGeneral: null,
  name: "",
};

export const initialProcessTypeGeneralItemView: ProcessTypeGeneralItemView = {
  processTypeGeneralItem: null,
  processTypeGeneralItemSubProcesses: [],
};

export const initialProcessTypeGeneralItemSubProcess: ProcessTypeGeneralItemSubProcess =
  {
    ...initialBaseModel,
    processTypeGeneralItem: null,
    name: "",
    timeMins: 0,
  };

export const initialProcessTypeGeneralItemSubProcessView: ProcessTypeGeneralItemSubProcessView =
  {
    processTypeGeneralItemSubProcess: null,
  };

export const initialPanelTemplate: PanelTemplate = {
  ...initialBaseModel,
  name: "",
  type: "",
  panelGroup: "",
  panelTemplateItems: [],

  panelTemplateMechanicalProcessTypeBlacklists: [],
  panelTemplateMechanicalProcessTypes: [],

  panelTemplateProcessTypeGeneralBlacklists: [],
  panelTemplateProcessTypeGenerals: [],

  panelTemplateProcessTypeGeneralItemBlacklist: [],
  panelTemplateProcessTypeGeneralItems: [],

  panelTemplateProcessTypeGeneralItemSubProcessBlacklist: [],
  panelTemplateProcessTypeGeneralItemSubProcesses: [],
};

export const initialPanelTemplateMechanicalProcessTypeBlacklist: PanelTemplateMechanicalProcessTypeBlacklist =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processType: null,
  };

export const initialPanelTemplateMechanicalProcessType: PanelTemplateMechanicalProcessType =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processType: null,
  };

export const initialPanelTemplateProcessTypeGeneralBlacklist: PanelTemplateProcessTypeGeneralBlacklist =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processTypeGeneral: null,
  };

export const initialPanelTemplateProcessTypeGeneral: PanelTemplateProcessTypeGeneral =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processTypeGeneral: null,
  };

export const initialPanelTemplateProcessTypeGeneralItemBlacklist: PanelTemplateProcessTypeGeneralItemBlacklist =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processTypeGeneralItem: null,
  };

export const initialPanelTemplateProcessTypeGeneralItem: PanelTemplateProcessTypeGeneralItem =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processTypeGeneralItem: null,
  };

export const initialPanelTemplateProcessTypeGeneralItemSubProcessBlacklist: PanelTemplateProcessTypeGeneralItemSubProcessBlacklist =
  {
    ...initialBaseModel,
    panelTemplate: null,
    processTypeGeneralItemSubProcess: null,
  };

export const initialPanelTemplateProcessTypeGeneralItemSubProcess: PanelTemplateProcessTypeGeneralItemSubProcess =
  {
    ...initialBaseModel,
    panelTemplate: null,
    timeMins: 0,
    processTypeGeneralItemSubProcess: null,
  };

export const initialExtProjectProductPanelTemplate: ExtProjectProductPanelTemplate =
  {
    ...initialBaseModel,
    extProjectId: null,
    extProjectProductId: null,
    panelTemplate: null,
  };

export const initialExtProjectWorkOrder: ExtProjectWorkOrder = {
  ...initialBaseModel,
  date: null,
  extProductId: null,
  extProjectId: null,
  extUserId: null,
  extProjectWorkOrderExtUsers: null,
  qty: 1,
  panelTemplateProcessTypeGeneralItemSubProcess: null,
};

export const initialPanelTemplateProcessTypeGeneralItemSubProcessWorker: PanelTemplateProcessTypeGeneralItemSubProcessWorker =
  {
    ...initialBaseModel,
    extUserId: null,
    timeMins: 0,
    panelTemplateProcessTypeGeneralItemSubProcess: null,
    workOrderUuid: null,
  };

export const initialWorkOrderRevWorker: WorkOrderRevWorker = {
  ...initialBaseModel,
  extUserId: null,
  workOrderRev: null,
};

export const initialProject: Project = {
  ...initialBaseModel,
  extProjectId: null,
  projectFeps: [],
  projectBoms: [],
};

export const initialProjectView: ProjectView = {
  project: null,
  projectFeps: [],
  projectBoms: [],
};

export const initialExtPurchaseRequestPostBody: ExtPurchaseRequestPostBody = {
  pr_number: "",
  request_from: "",
  purpose: "",
  purpose_remark: "",
  request_mode: 1,
  pr_date: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
    .toISOString()
    .split("T")[0],
  pr_target: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
    .toISOString()
    .split("T")[0],
  created_by: "",

  // "product_id[]": 0,
  // "mfr[]": "",
  // "part_num[]": "",
  // "part_desc[]": "",
  // "curr[]": "",
  // "unit_cost[]": 0,
  // "qty_pr[]": 0,
  // "um_pr[]": "",
};

export const initialExtPurchaseRequestPostBodyJson: ExtPurchaseRequestPostBodyJson =
  // {
  //   prNumber: "none",
  //   requestFrom: "none",
  //   purpose: "none",
  //   purposeRemark: "none",
  //   requestMode: 1,
  //   prDate: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
  //     .toISOString()
  //     .split("T")[0],
  //   prTarget: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
  //     .toISOString()
  //     .split("T")[0],
  //   createdBy: "none",
  //   extPurchaseRequestPostBodyItemsJson: [],
  //   jobId: 0,
  // };
  {
    prNumber: "",
    requestFrom: "",
    purpose: "",
    purposeRemark: "",
    requestMode: 1,
    prDate: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
      .toISOString()
      .split("T")[0],
    prTarget: new Date(`${makeDateString(new Date())}T00:00:00.000Z`)
      .toISOString()
      .split("T")[0],
    createdBy: "",
    extPurchaseRequestPostBodyItemsJson: [],
    jobId: 0,
  };

export const initialExtPurchaseRequestPostBodyItemJson: ExtPurchaseRequestPostBodyItemJson =
  {
    curr: "RP",
    mfr: "none",
    partDesc: "none",
    partName: "none",
    partNum: "none",
    productId: 0,
    qtyPr: 0,
    umPr: "EA",
    unitCost: 0,
    jobId: 0,
    id: null
  };

export const defaultJobFep: JobFep = {
  ...{ ...initialBaseModel },
  fepDocument: null,
  job: null,
};
export const defaultBomLeveledRecursive: BomLeveledRecursive = {
  bomLeveled: null,
  bomLeveledIndividualEntities: null,
  children: []
}

export const defaultBomLeveled: BomLeveled = {
  ...initialBaseModel,
  name: "",
  bomLeveled: null,
  children: [],
  extItemId: null,
  extCreatedById: null,
  qty: null,
  type: null,
  forProcess: null,
  bomLeveledGroup: null,
  bomLeveledSubGroup: null,
  bomLeveledsChildren: null,
  remark: "",
  bomLeveledIndividualEntities: null,
  qtyView: null,
  identifier: null,
  itemsCount: null,
  extItemToCreateId: null,
  elecExists: null,
  mechExists: null,
  extRequestItemId: null,
  materialType: null,
  approvals: null,
  snapshotPriceIdr: null,
  snapshotPriceLast: null,
  deactivationDate: null,
  inactive: null,
  revision: 0,
};

export const defaultFepLeveled: FepLeveled = {
  ...initialBaseModel,
  name: "",
  fepLeveled: null,
  fepLeveledParts: [],
  programName: "",
  qty: 0,
  timeSeconds: 0,
  type: null,
  fepLeveledFepLeveledPrograms: [],
};

export const defaultFepLeveledView: FepLeveledView = {
  fepLeveled: { ...defaultFepLeveled },
  fepLeveledFepLeveledPrograms: [],
  fepLeveledParts: [],
};

export const defaultFepLeveledPart: FepLeveledPart = {
  ...initialBaseModel,
  name: "",
  item: null,
  fepLeveledPartFepLeveledPartProcesses: null,
  qty: 0,
};

export const defaultFepLeveledProgram: FepLeveledProgram = {
  ...initialBaseModel,
  name: "",
  time: 0,
  bendTime: 0,
  coatTime: 0,
  area: 0.0,
  weldGrindTime: 0,
  runs: 0,
  fepLeveledProgramFepLeveledProgramProcess: [],
};

export const defaultFepLeveledFepLeveledProgramView: FepLeveledFepLeveledProgramView =
  {
    fepLeveledFepLeveledProgram: null,
    fepLeveled: null,
    fepLeveledProgram: null,
  };

export const defaultFepLeveledFepLeveledProgram: FepLeveledFepLeveledProgram = {
  ...initialBaseModel,
  fepLeveled: null,
  fepLeveledProgram: null,
};

export const defaultFepLeveledPartView: FepLeveledPartView = {
  fepLeveledPart: null,
};

export const defaultJobBomLeveled: JobBomLeveled = {
  ...initialBaseModel,
  bomLeveled: null,
  job: null,
  qty: 0,
  extPanelCodeId: null,
  panelCode: null,
};

export const defaultJobBomLeveledView: JobBomLeveledView = {
  jobBomLeveled: null,
};

export const defaultJobFepLeveled: JobFepLeveled = {
  ...initialBaseModel,
  fepLeveled: null,
  job: null,
};

export const defaultJobFepLeveledView: JobFepLeveledView = {
  jobFepLeveled: null,
  fepLeveledFepLeveledPrograms: [],
};

export const initialMaterialRequest: MaterialRequest = {
  ...initialBaseModel,
  bom: null,
  job: null,
  jobData: null,
  materialRequestItems: [],
  materialRequestNumber: "",
  name: "",
  neededDate: "",
  referenceType: "",
  status: 0,
  updatedAt: null,
  remark: "",
  createdBy: null,
  extItemId: 0,
  jobId: null,
  cancellationNote: null,
  extRequestedById: null,
  originalName: null,
  type: null,
  panelCode: null,
  extCrmJournalId: null,
  partNumCategory: null,
  materialRequestAuthorizations: null,
  needAuthorization: null,
  uniqueMR: null,
  uniqueMRRemark: null,
  titipan: null,
  isBulk: null
};

export const defaultJobSummary: JobSummary = {
  ...initialBaseModel,
  name: "",
  dateIssued: null,
  deadline: null,
  expectedDays: 0,
  poNumber: "",
  jobs: [],
  jobSummaryExtPurchaseOrders: [],
};
export const defaultJobSummaryView: JobSummaryView = {
  jobSummary: { ...defaultJobSummary },
  jobsView: [],
  jobsMapped: [],
  jobSummaryExtPurchaseOrders: [],
  jobFepDeleteIds: [],
};

export const defaultJobView: JobView = {
  job: { ...initialJob },
};

export const defaultJobMapped: JobMapped = {
  job: null,
  jobBomLeveleds: [],
  jobBomLeveledsMapped: [],
  jobFepLeveleds: [],
  jobMaterialRequests: [],
  jobPurchaseRequests: [],
  fepDocuments: [],
  jobIntegrationList: null,
};

export const defaultJobSummaryExtPurchaseOrderView: JobSummaryExtPurchaseOrderView =
  {
    jobSummaryExtPurchaseOrder: null,
  };

export const defaultJobSummaryExtPurchaseOrder: JobSummaryExtPurchaseOrder = {
  ...initialBaseModel,
  jobSummary: null,
  extPurchaseOrder: null,
};

export const defaultExtPurchaseOrder: ExtPurchaseOrder = {
  ...initialBaseModel,
  name: "",
  extPurchaseOrderId: null,
};

export const defaultMaterialRequestView: MaterialRequestView = {
  materialRequest: null,
  materialRequestMaterialRequestItems: [],
  reserveList: [],
  materialRequestItems: [],
};

export const defaultMaterialRequestMaterialRequestItemView: MaterialRequestMaterialRequestItemView =
  {
    materialRequestMaterialRequestItem: null,
  };

export const defaultMaterialRequestMaterialRequestItem: MaterialRequestMaterialRequestItem =
  {
    ...initialBaseModel,
    materialRequest: null,
    materialRequestItem: null,
  };

export const defaultMaterialRequestItemView: MaterialRequestItemView = {
  materialRequestItem: null,
};

export const initialPanelCodeDepartmentTemplate: PanelCodeDepartmentTemplate = {
  ...initialBaseModel,
  panelCode: null,
  departmentTemplate: null,
  timeProcessMins: null,
  extTaskListId: null,
};

export const initialDepartmentTemplate: DepartmentTemplate = {
  ...initialBaseModel,
  panelCode: null,
  departmentTemplateItems: null,
  departmentTemplateItemsData: null,
  name: null,
  tProcessMins: null,
  processDays: null,
  variable: null,
  commonId: null,
  departmentTemplateRule: null,
  extHeadId: null,
  extDepartmentId: null,
  projectEditable: null,
  isProject: null,
  extAppId: null,
  type: null
};

export const initialPanelCodeDepartmentTemplateItem: PanelCodeDepartmentTemplateItem =
  {
    ...initialBaseModel,
    panelCode: null,
    departmentTemplateItem: null,
    timeProcessMins: null,
    panelCodeDepartmentTemplateItemManpowers: null,
    endDate: null,
    startDate: null,
    completedDate: null,
    excluded: null,
    numComments: null,
  };

export const defaultIntegration: Integration = {
  ...initialBaseModel,
  integrationModules: null,
  job: null,
  name: null,
  extDefaultQaId: null,
  extDefaultQcId: null,
  extDefaultAutoAssignSpvId: null,
  category: null,
};

export const defaultIntegrationModule: IntegrationModule = {
  ...initialBaseModel,
  integration: null,
  integrationModule: null,
  integrationModules: [],
  item: null,
  name: null,
  qty: null,
  type: null,
  tProcess: null,
  timeProcessMins: null,
};

export const defaultJobIntegrationList: JobIntegrationList = {
  ...initialBaseModel,
  integration: null,
  job: null,
  panelCode: null,
};

export const defaultJobIntegrationListView: JobIntegrationListView = {
  jobIntegrationList: null,
  integrationRecursive: null,
};

export const defaultPanelCodeMachineProgram: PanelCodeMachineProgram = {
  ...initialBaseModel,
  machineProgram: null,
  panelCode: null,
  qty: null,
};

export const defaultPanelCodeSerialNumber: PanelCodeSerialNumber = {
  ...initialBaseModel,
  manualSerialNumber: null,
  panelCode: null,
  sequentialSerialNumber: null,
  done: null,
};

export const defaultBomleveledIndividualEntities: BomLeveledIndividualEntity = {
  ...initialBaseModel,
  bomLeveled: null,
  individualEntity: null,
};

export const defaultSimplifiedProcessType: SimplifiedProcessType = {
  name: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  extPicId: null,
  extSpvId: null,
  machine: null,
  simplifiedProcessTypePics: null,
};

export const defaultMachinePic: MachinePic = {
  ...initialBaseModel,
  extUserId: null,
  machine: null,
};

export const defaultJobPanelCodeSerialNumberDone: JobPanelCodeSerialNumberDone =
  {
    ...initialBaseModel,
    job: null,
    panelCodeSerialNumber: null,
    qty: null,
    completedDate: null,
    done: null,
  };

export const initialPanelCodeSerialNumberManual: PanelCodeSerialNumberManual = {
  ...initialBaseModel,
  panelCode: null,
  serialNumber: null,
};
export const initialPanelCodeRemark: PanelCodeRemark = {
  ...initialBaseModel,
  panelCode: null,
  remark: null,
  done: null,
};

export const initialMeetingTaskInCharge: MeetingTaskInCharge = {
  creator: null,
  extUserId: 0,
  meetingTask: null,
  rateStars: null,
  createdAt: null,
  extCreatedById: null,
  hidden: null,
  id: null,
  ordering: null,
  updatedAt: null,
  uuid: null,
  isQa: null,
  isQc: null,
  totalTimeHours: null,
  totalTimeHoursTask: null,
};
export const defaultPanelCodeSerialNumberRect: PanelCodeSerialNumberRect = {
  name: null,
  panelCode: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  extPanelCodeSerialNumberId: null,
  extPanelCodeSerialNumberManualId: null,
};
export const initialMaterialRequestAuthorization: MaterialRequestAuthorization =
  {
    extUserId: null,
    materialRequest: null,
    createdAt: null,
    extCreatedById: null,
    hidden: false,
    id: null,
    ordering: null,
    unchangeable: null,
    updatedAt: null,
    uuid: "",
    version: null,
  };
  

export const initialBomLeveledApproval: BomLeveledApproval = {
  bomLeveled: null,
  extUserId: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
};

export const initialMachineProgramExclusion: MachineProgramExclusion = {
  excluded: null,
  extMachineProgramId: null,
  panelCode: null,
  createdAt: null,
  extCreatedById: null,
  hidden: false,
  id: null,
  ordering: null,
  unchangeable: null,
  updatedAt: null,
  uuid: "",
  version: null,
  extSimplifiedProcessTypeId: null,
};
